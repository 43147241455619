<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Shortcut Obat dan Alkes'">
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <Form
              :form="form"
              :route="'medicine-packages/' + form.id"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/medicine-packages/FormEdit.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
       package_name:"",
       action_id:"",
       medicines:""
      },
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('medicine-packages/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/medicine-packages/list')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
      
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
     { title: "Shortcut Obat dan Alkes", route: "" },
      { title: "Daftar Shortcut Obat dan Alkes", route: "/medicine-packages/list" },
      { title: "Ubah" },
    ])
    // Get Data
    this.get()
  },

}

</script>